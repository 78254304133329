'use client';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

/*
const Puller = styled(Box)(({ theme }) => ({
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF',
    borderRadius: '100px',
    position: 'absolute',
    top: window.innerHeight * 0.91,
    left: 'calc(50% - 60px)',
}));
*/

const StyledSwipeableDrawer = styled(SwipeableDrawer, {
    shouldForwardProp: prop => prop !== 'drawerHeight',
    /* @ts-ignore */
})(({ drawerHeight }) => ({
    '& .MuiPaper-root': {
        width: '100vw',
        maxHeight: '100%',
        height: drawerHeight ? drawerHeight : 'auto',
        padding: '16px 16px 0px',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        overflow: 'auto',
    },
    '& .MuiBackdrop-root': {
        backdropFilter: 'blur(3px)',
    },
}));

const BottomSheet: React.FC<{
    children: React.ReactElement[] | React.ReactElement;
    open: boolean;
    setOpen?: (flag: boolean) => void;
    heightInput?: string;
    onOpen?: () => void;
    onClose?: () => void;
    className?: any;
    drawerHeight?: string;
}> = ({
    children,
    open,
    setOpen,
    onClose,
    onOpen,
    className,
    drawerHeight,
}) => {
    const toggleDrawer = (newOpen: boolean) => () => {
        if (!newOpen && onClose) {
            onClose();
        }
        if (newOpen && onOpen) {
            onOpen();
        }
        if (setOpen) setOpen(newOpen);
    };

    return (
        <Box
            sx={{
                backdropFilter: 'blur(3px)',
                backgroundColor: 'rgba(0,0,30,0.4)',
            }}
        >
            {/* @ts-ignore */}
            <StyledSwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={0}
                disableSwipeToOpen={true}
                disableBackdropTransition
                ModalProps={{
                    keepMounted: true,
                }}
                /* @ts-ignore */
                drawerHeight={drawerHeight}
            >
                <StyledBox
                    sx={{
                        height: '100%',
                    }}
                    className={`${className}`}
                >
                    <>{children}</>
                </StyledBox>
                {/* <StyledBox
          sx={{
            position: 'absolute',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        > */}
                {/* <Puller /> */}
                {/* </StyledBox> */}
            </StyledSwipeableDrawer>
        </Box>
    );
};
export default BottomSheet;
