'use client';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

const getHash = () =>
    typeof window !== 'undefined'
        ? decodeURIComponent(window.location.hash.replace('#', ''))
        : undefined;

export const useHash = () => {
    const [hash, setHash] = useState(getHash());
    const router = useRouter();

    const setHashUrl = (hashString: string) => {
        window.location.hash = hashString;
        setHash(hashString);
    };

    const resetHashUrl = () => {
        router.replace('', {});
        setHash('');
    };

    useEffect(() => {
        const handleHashChange = () => {
            setHash(getHash());
        };

        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return { hash, setHashUrl, resetHashUrl };
};
