function To<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[U, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        console.trace('reporting errorExt from ToPromise lib');
        console.error(errorExt);
        const parsedError = { ...err, ...errorExt };
        return [parsedError, undefined];
      }
      console.trace('reporting err from ToPromise lib');
      console.error(err);
      return [err, undefined];
    });
}

export default To;
