//just static compenent shouldn't be client.
//'client-use';
import classes from './styles.module.scss';
import { ITypography } from './typography.types';

const Typography = ({
    variant = '',
    style,
    children,
    color,
    className = '',
    component = '',
}: ITypography) => {
    const isDevEl = component === 'div';
    const isPEl = component === 'p' || component === '';
    const isSpanEl = component === 'span';
    const _style = color ? { ...style, color } : style;

    return (
        <>
            {isPEl && (
                <p
                    style={{ ..._style }}
                    className={`${classes[variant]} ${className}`}
                >
                    <>{children}</>
                </p>
            )}
            {isDevEl && (
                <div
                    style={_style}
                    className={`${classes[variant]} ${className}`}
                >
                    <>{children}</>
                </div>
            )}
            {isSpanEl && (
                <span
                    style={{ ..._style }}
                    className={`${classes[variant]} ${className}`}
                >
                    <>{children}</>
                </span>
            )}
        </>
    );
};
export default Typography;
