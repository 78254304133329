'use client';
import { authStorage } from './SylndrStorage';
import { authTokenKey, COMMON_SERVER_HEADERS, userIdKey } from '@/constants';
import Logger from '@/utils/logger';
import i18n from '@/translations/client-setup';

class FetchWithInterceptor {
    private static instance: FetchWithInterceptor;

    public static getInstance(): FetchWithInterceptor {
        if (!FetchWithInterceptor.instance) {
            FetchWithInterceptor.instance = new FetchWithInterceptor();
        }
        return FetchWithInterceptor.instance;
    }
    public async use({ url, method, body = null }) {
        const userToken = authStorage.getItem(authTokenKey);
        const requestHeaders: any = {
            'Content-Type': 'application/json',
            lang: `${i18n.language}`,
            ...COMMON_SERVER_HEADERS,
        };
        if (userToken && userToken.length > 0) {
            requestHeaders.Authorization = `Bearer ${userToken}`;
        }
        const fetchOptions: any = {
            method,
            headers: requestHeaders,
        };
        if (body) {
            fetchOptions.body = JSON.stringify(body);
        }
        const res = await fetch(url, fetchOptions)
            .then(async response => {
                const parsedResponse = await response.json();
                if (!response.ok) {
                    throw new Error(
                        JSON.stringify({
                            ...parsedResponse,
                            status: response.status,
                        })
                    );
                }
                return parsedResponse;
            })
            .catch(error => {
                const parsedError = JSON.parse(error.message);
                if (
                    parsedError &&
                    parsedError.status &&
                    parsedError.status == 401
                ) {
                    this.executeTokenExpirationLogic();
                }

                throw parsedError;
            });
        return res;
    }
    executeTokenExpirationLogic() {
        try {
            authStorage.deleteItem(authTokenKey);
            authStorage.deleteItem(userIdKey);
            const pathname = window.location.pathname;
            const currentLang = pathname.split('/')[1];
            window.location.href = `/${currentLang}/register?action=logout`;
        } catch (error) {
            Logger.error('expired token logic error');
            Logger.error(error);
        }
    }
}

export const fetchWithInterceptorInstance = FetchWithInterceptor.getInstance();
