'use client';
import { useEffect, useState } from 'react';
import useDebounce from '@/hook/debounce.hook';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
        IS_DESKTOP: width >= 850,
    };
}
export enum DimensionsTargets {
    SERVER = 'server',
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}
export interface IUseWindowDimensions {
    width: number;
    height: number;
    IS_DESKTOP: boolean;
}
export default function useWindowDimensions(
    target: DimensionsTargets = DimensionsTargets.SERVER
): IUseWindowDimensions {
    let defaults: IUseWindowDimensions;
    if (target === DimensionsTargets.SERVER) {
        defaults = { width: -1, height: -1, IS_DESKTOP: false };
    } else if (target === DimensionsTargets.DESKTOP) {
        defaults = { width: 1200, height: 1200, IS_DESKTOP: true };
    } else if (target === DimensionsTargets.MOBILE) {
        defaults = { width: 600, height: 600, IS_DESKTOP: false };
    }

    const [windowDimensions, setWindowDimensions] =
        useState<IUseWindowDimensions>(defaults);
    useEffect(() => {
        setWindowDimensions(getWindowDimensions());
    }, []);

    const handleResize = useDebounce(() => {
        setWindowDimensions(getWindowDimensions());
    }, 300);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
