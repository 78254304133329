import { default as NextLink } from 'next/link';

const Link = (props: any) => {
    // @TODO:: soon will allow prefetch again after get the error.
    // if (props.prefetch) {
    //     return <NextLink {...props} />;
    // }
    return <NextLink {...props} prefetch={false} />;
};

export default Link;
