'use client';

import { useTranslation } from 'react-i18next';
import { usePathname, useRouter } from 'next/navigation';
import { AVAILABLE_LANGUAGES } from '@/constants';
import { useEffect, useState } from 'react';

const useTranslationHook = () => {
    const path = usePathname();
    const lang = path.split('/')[1];
    const { t, i18n } = useTranslation();
    const [isSwitching, setSwitching] = useState(false);
    const router = useRouter();
    const SwitchLanguage = (
        e: React.MouseEvent<HTMLElement>,
        currentLang: AVAILABLE_LANGUAGES,
        englishTarget: string,
        arabicTarget: string
    ) => {
        e.stopPropagation();
        if (!isSwitching) {
            setSwitching(true);
            const targetLang =
                currentLang === AVAILABLE_LANGUAGES['ar']
                    ? AVAILABLE_LANGUAGES['en']
                    : AVAILABLE_LANGUAGES['ar'];
            router.push(
                targetLang === AVAILABLE_LANGUAGES['ar']
                    ? arabicTarget
                    : englishTarget
            );
            // (async () => {
            //     await i18n.changeLanguage(targetLang, () => {
            //         setSwitching(false);
            //         window.location.href =
            //             targetLang === AVAILABLE_LANGUAGES['ar']
            //                 ? arabicTarget
            //                 : englishTarget;
            // });
            // })();
        }
    };
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient && lang && i18n.language !== lang) {
        i18n.changeLanguage(lang);
    }

    const IS_RTL = i18n.language === AVAILABLE_LANGUAGES.ar;

    return {
        t,
        i18n,
        IS_RTL,
        SwitchLanguage,
    };
};
export default useTranslationHook;
